import {
  AfterViewInit,
  Component,
  DoCheck,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  ECaseConfirmDialogComponent,
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils,
  ECaseUtilsGlobal,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';
import {DialogService} from '../../formbuilderForms/form/dialog.service';
import cloneDeep from "lodash/cloneDeep";
import {PageEvent} from "@angular/material/paginator";


@Component({
  selector: 'fb-table',
  templateUrl: './fb-table.component.html',
  styleUrls: ['./fb-table.component.scss']
})
export class FbTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() showSaveNavBar: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  @Input() teamTableIndex: number;
  @Input() sections;
  @Input() teamTableBlockName: string;
  @Input() teamTableNestedTableBlockName: string;
  originalValueForShowSaveNavBar = true;
  showDetails = false;
  selectedTableRowIndex = -1;
  copyBlockData: any;
  subscription: Subscription;
  formValidationSubscription: Subscription;
  globalComponent;
  widthDividerForDynamicWidth = 1;
  tableConfChange;
  isDragActive: boolean;
  rowIndexBeingDragged: number;
  beforeFilteringRows = [];
  showFilteredImage = true;
  pageSizeOptions = [5, 10, 25, 100];
  pageSize = 10;
  pageIndex = 0;
  hideEditButton: boolean;

  constructor(private translate: TranslateService, private matDialog: MatDialog, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe, private eCaseHttpService: EcaseHttpService,
              private refreshDataService: RefreshDataService, private dialogService: DialogService, private eCaseSnackBarService: ECaseSnackBarService,
              private pendingChangesGuard: PendingChangesGuard) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.tableConfChange = this.confChange;
    this.hideEditButton = false;
  }


  openDialog(confChange) {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: confChange.dialogText, selectedLanguage: this.selectedLanguage}
    });
    console.log('donee');
  }

  onDrop(event): void {
    if (this.confChange.isTablePaginated) {
      const previousIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      console.log(previousIndex);
      console.log(currentIndex);
      if (this.confChange.onRowDragEndFunction) {
        this.confChange.onRowDragEndFunction(this.globalPrj, this.globalConf, previousIndex, currentIndex, this.selectedLanguage);
      }
      if (this.pageIndex === 0) {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = index > (this.pageSize  - 1);
          return row;
        });
      } else {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = (index < ((this.pageIndex * this.pageSize) - 1)) || (index > ((this.pageIndex * this.pageSize) + this.pageSize - 1));
          return row;
        });
      }
      if (Math.abs(currentIndex - previousIndex) === 1) {
        [this.bphChange.rows[currentIndex], this.bphChange.rows[previousIndex]] = [this.bphChange.rows[previousIndex], this.bphChange.rows[currentIndex]];
      } else {
        [this.bphChange.rows[currentIndex], this.bphChange.rows[previousIndex]] = [this.bphChange.rows[previousIndex], this.bphChange.rows[currentIndex]];
        // previousIndex >= currentIndex ? this.bphChange.rows.splice(currentIndex, 0, this.bphChange.rows.splice(previousIndex, 1)[0]) : this.bphChange.rows.splice(currentIndex - 1, 0, this.bphChange.rows.splice(previousIndex, 1)[0]);
      }
    } else {
      if (this.confChange.onRowDragEndFunction) {
        this.confChange.onRowDragEndFunction(this.globalPrj, this.globalConf, event.previousIndex, event.currentIndex, this.selectedLanguage);
      }
      if (Math.abs(event.currentIndex - event.previousIndex) === 1) {
        [this.bphChange.rows[event.currentIndex], this.bphChange.rows[event.previousIndex]] = [this.bphChange.rows[event.previousIndex], this.bphChange.rows[event.currentIndex]];
      } else {
        event.previousIndex >= event.currentIndex ? this.bphChange.rows.splice(event.currentIndex, 0, this.bphChange.rows.splice(event.previousIndex, 1)[0]) : this.bphChange.rows.splice(event.currentIndex - 1, 0, this.bphChange.rows.splice(event.previousIndex, 1)[0]);
      }
    }

    this.pendingChangesGuard.isPristine = false;
    this.isDragActive = false;
  }

  updateDragStatus(event, rowIndex): void {
    this.rowIndexBeingDragged = rowIndex;
    this.isDragActive = true;
    const documentInputField = document.getElementsByClassName('cdk-drag-preview')[0];
    if (documentInputField) {
      const curStyle = documentInputField.getAttribute('class');
      if (!curStyle.includes(' inDrag')) {
        documentInputField.setAttribute('class', ((curStyle ? curStyle : '') + ' inDrag'));
      }
    }
  }

  resetFilters(): void {
    console.log("got inside reset filter")
    this.bphChange.rows = cloneDeep(this.beforeFilteringRows);
    this.pageIndex = 0;
    if (this.confChange.isTablePaginated) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (this.pageSize  - 1);
        return row;
      });
    }
    this.showFilteredImage = true;
    this.confChange.subBlocks.forEach(subBlock => {
      subBlock.valueToFilter = '';
    });
  }

  isFilterFieldVisible(subBlock): boolean {
    return subBlock.type === 'text' || subBlock.type === 'statictext' || subBlock.type === 'select' || subBlock.type === 'AutoComplete';
  }

  applyFilter(event, subBlock): void {
    if (this.confChange.isColumnsFiltered) {
      if (!event.target.value || event.target.value === '') {
        this.resetFilters();
      } else {
        this.pageIndex = 0;
        //this.beforeFilteringRows = this.bphChange.rows;
        this.bphChange.rows = this.bphChange.rows.filter(row => {
          if (subBlock.type === 'text' || subBlock.type === 'statictext') {
            if (!row[subBlock.name].value) {
              row[subBlock.name].value = ''
            }
            return row[subBlock.name].value.toString().toLowerCase().includes(event.target.value.toLowerCase());
          } else if (subBlock.type === 'select' || subBlock.type === 'AutoComplete') {
            if (!row[subBlock.name].value) {
              row[subBlock.name].value = {};
              row[subBlock.name].value.label = {};
              row[subBlock.name].value.label[this.selectedLanguage] = '';
            }
            if (!row[subBlock.name].value.label) {
              row[subBlock.name].value.label = {};
              row[subBlock.name].value.label[this.selectedLanguage] = '';
            }
            return row[subBlock.name].value.label[this.selectedLanguage].toLowerCase().includes(event.target.value.toLowerCase());
          } else {
            return true;
          }
        });
        if (this.confChange.isTablePaginated) {
          this.bphChange.rows = this.bphChange.rows.map((row, index) => {
            row.isHidden = index > (this.pageSize  - 1);
            return row;
          });
        }
        this.showFilteredImage = false;
      }
    }
  }



  updatePagination(pageEvent: PageEvent): void {
    if (pageEvent.pageIndex === 0) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (pageEvent.pageSize - 1);
        return row;
      });
    } else {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = (index <= ((pageEvent.pageIndex * pageEvent.pageSize) - 1)) || (index > ((pageEvent.pageIndex * pageEvent.pageSize) + pageEvent.pageSize - 1));
        return row;
      });
    }
  }



  sortRows(subBlock: any): void {
    if (subBlock.isSortable) {
      if (subBlock.sortDirection === 'asc') {
        subBlock.sortDirection = 'desc';
      } else if (subBlock.sortDirection === 'desc') {
        subBlock.sortDirection = 'asc';
      } else {
        subBlock.sortDirection = 'asc';
      }
      this.confChange.subBlocks.filter(e => e.name !== subBlock.name).forEach(block => {
        block.sortActive = false;
      })
      const direction = subBlock.sortDirection === 'asc' ? 1 : -1
      this.bphChange.rows = this.bphChange.rows.sort((a, b) => {
        if (subBlock.type === 'text' || subBlock.type === 'statictext') {
          if (!a[subBlock.name].value) {
            a[subBlock.name].value = ''
          }
          if (!b[subBlock.name].value) {
            b[subBlock.name].value = ''
          }
          return (direction * a[subBlock.name].value.toString().toLowerCase().localeCompare(b[subBlock.name].value.toString().toLowerCase()));
        } else if (subBlock.type === 'select' || subBlock.type === 'AutoComplete') {
          if (!a[subBlock.name].value) {
            a[subBlock.name].value = {};
            a[subBlock.name].value.label = {};
            a[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!a[subBlock.name].value.label) {
            a[subBlock.name].value.label = {};
            a[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!b[subBlock.name].value) {
            b[subBlock.name].value = {};
            b[subBlock.name].value.label = {};
            b[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          if (!b[subBlock.name].value.label) {
            b[subBlock.name].value.label = {};
            b[subBlock.name].value.label[this.selectedLanguage] = '';
          }
          return (direction * a[subBlock.name].value.label[this.selectedLanguage].toString().toLowerCase().localeCompare(b[subBlock.name].value.label[this.selectedLanguage].toString().toLowerCase()));
        } else {
          return 0
        }
      });
      this.pageIndex = 0;
      if (this.confChange.isTablePaginated) {
        this.bphChange.rows = this.bphChange.rows.map((row, index) => {
          row.isHidden = index > (this.pageSize  - 1);
          return row;
        });
      }
      subBlock.sortActive = true;
    }
  }

  ngOnInit() {
    this.originalValueForShowSaveNavBar = cloneDeep(this.showSaveNavBar);
    console.log('BPHCHANGE');
    console.log(this.bphChange);
    this.tableConfChange = this.confChange;
    this.widthDividerForDynamicWidth = this.confChange.subBlocks.length;
    if (this.confChange.paginationValues) {
      this.pageSizeOptions = this.confChange.paginationValues.split(',').map(e => Number(e));
      this.pageSize = this.pageSizeOptions[0];
    }

    if (this.confChange.readOnlyTable) {
      this.widthDividerForDynamicWidth = this.widthDividerForDynamicWidth + 1;
    }
    if (this.confChange.showDelete) {
      this.widthDividerForDynamicWidth = this.widthDividerForDynamicWidth + 1;
    }
    if (!this.confChange.tableLabel) {
      this.confChange.tableLabel = {};
    }
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }

    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }

    if (this.bphChange.rows.length === 0 && this.confChange.listName) {
      const filteredLov = this.globalConf.lovs[this.confChange.listName];
      for (let ii = 0; ii < filteredLov.list.length; ii++) {
        this.addRow(this.bphChange, this.confChange);
      }
    }
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;
    });*/
    this.subscription = this.refreshDataService.onNewTableRowAdd().subscribe((data) => {
      if (data.confChange.name === this.confChange.name) {
        this.addRow(data.bphChange, data.confChange);
      }
    });
    this.formValidationSubscription = this.refreshDataService.onFormValidation().subscribe((data) => {
      if (data) {
        console.log('Form validated');
        this.globalPrj = data;
        if (this.teamTableBlockName && this.teamTableNestedTableBlockName) {
          this.bphChange = this.globalPrj[this.selectedSectionId][this.teamTableBlockName].rows[this.teamTableIndex][this.teamTableNestedTableBlockName];
        } else {
          this.bphChange = this.globalPrj[this.selectedSectionId][this.confChange.name];
        }
      }
    });
    this.beforeFilteringRows = cloneDeep(this.bphChange.rows);
    this.confChange.subBlocks.forEach(subBlock => {
      subBlock.valueToFilter = '';
    });
    if (this.confChange.isTablePaginated) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (this.pageSize  - 1);
        return row;
      });
    }

    this.refreshDataService.getNewTableList().subscribe((data) => {
      console.log('change table list')
      if (data && (data.name === this.confChange.name) && data.rows) {
        this.bphChange.rows = data.rows;
        this.beforeFilteringRows = cloneDeep(this.bphChange.rows)
      }
    });
  }

  getTranslatedLabel(key: string) {

    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  getColumnTotalLabel(confBlock) {
    return confBlock.columnTotalLabel ?
        confBlock.columnTotalLabel[this.selectedLanguage] :
        this.getTranslatedLabel("ecase.common.globaltotaltext")
  }

  ngAfterViewInit() {
    this.confChange.subBlocks = this.confChange.subBlocks.map((subBlock) => {
      subBlock.templateName = subBlock.type;
      subBlock.templateContext = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': false,
        'isValidTable': !subBlock.readOnlyTable,
        'isValidTableReadonly': subBlock.readOnlyTable
      };
      subBlock.templateContext2 = {
        'bphChange': this.bphChange,
        'confChange': subBlock,
        'isValidHtml': true,
        'isValidTable': false,
        'isValidTableReadonly': false
      };
      return subBlock;
    });
  }

  executeFunction(confChange, bphChange, rowIndex) {
    if (confChange.updateOutputEventFunctions) {
      this.globalConf.http = this.eCaseHttpService;
      this.globalConf.teamTableIndex = this.teamTableIndex;
      for (const functionName of confChange.updateOutputEventFunctions) {
        confChange[functionName](bphChange.value, rowIndex, this.globalPrj, this.globalConf, this.selectedLanguage, this.refreshDataService);
      }
    }
  }


  doNothing() {
  }

  getAlignClass(): string {
    switch (this.confChange.totalTextAlign) {
      case  'start':
        return 'align-start';
      case 'end' :
        return 'align-end';
      case undefined :
        return '';
    }
  }

  addRow(blockData, blockConf) {
    blockConf.isAddRowFunctionActive = true;
    blockConf.isEditRowFunctionActive = false;
    blockConf.showDetails = false;
    blockData.showDetails = false;

    this.confChange = _.cloneDeep(blockConf);
    this.bphChange = _.cloneDeep(blockData);
    this.copyBlockData = _.cloneDeep(this.bphChange);
    console.log('addRow Table');
    console.log(this.confChange);
    console.log(this.bphChange);
    if (!this.confChange.tableLabel) {
      this.bphChange.globalShowDetail = false;
    }
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
      ECaseUtils.scrollToTop('.content-wrapper');
    }

    this.confChange = blockConf;
    blockData.rows = blockData.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(ECaseUtils.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    blockConf.subBlocks.forEach(function(entity) {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });
    blockData.rows.push(aRow);
    blockData['selectedRowIndex'] = blockData.rows.length - 1;
    this.selectedTableRowIndex = blockData.rows.length - 1;
    if (blockConf.readOnlyTable) {
      blockData.selectedRow = aRow;
      blockConf.showDetails = true;
      blockData.showDetails = true;
      this.globalConf.showDetails = true;
      if (blockConf.name != 'sub_table') {
        for (let k = 0; k < globalArray.length; k++) {
          try {
            ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
            ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).showDetails = false;
            ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
          } catch (e) {
          }
        }
        if (this.confChange.selectedContainerName) {
          this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
            if (row.sub_table) {
              row.sub_table.showDetails = false;
            }
          });
        }
        blockData.showDetails = true;
      }
      blockConf.subBlocks.forEach(function(entity) {
        aRow[entity.name].globalShowDetail = true;
      });
      window.scrollTo(0, 0);
    }
    if (blockConf.type !== 'SchedulerTable') {
      if (!this.confChange.tableLabel[this.selectedLanguage]) {
        console.log('no label');
        this.bphChange.globalShowDetail = false;
      } else {
        this.bphChange.globalShowDetail = true;
      }
    }
    if (this.confChange.addRowFunction) {
      this.confChange.addRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    this.bphChange = blockData;
    this.confChange = blockConf;
    this.pendingChangesGuard.isPristine = false;
  }

  getTotal(rows, column, j, tcolumn, row, currency, totalText, type, confChange) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
        row[column]['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed);
    }
  }

  mathFloor(number) {
    return Math.floor(number);
  }

  parseInt(number) {
    return parseInt(number, 10);
  }

  multiYearRowIndexCalculator(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return rowIndex % (noOfYears + 1);
  }

  getHeaderRowIndex(rowIndex, noOfYears) {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return (noOfYears + 1) * (Math.floor(rowIndex / (noOfYears + 1)));
  }


  closeDetails(blockConf, blockData) {

    console.log('fsdfsd');
    console.log(blockConf);
    console.log(blockData);

    blockConf.showDetails = false;
    blockData.showDetails = false;
    this.globalConf.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if (this.confChange.selectedContainerName) {
      this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
        if (row.sub_table) {
          row.sub_table.showDetails = false;
        }
      });
    }
    if (this.confChange.nonEditableField) {
      this.confChange.nonEditableField.forEach((field) => {
        this.bphChange.rows[this.selectedTableRowIndex][field].disabled = true;
      });
    }
    if (blockConf.isAddRowFunctionActive) {
      blockConf.addRow(this.globalPrj, this.globalConf, this, this.selectedLanguage, blockData);
    }
    if (blockConf.isEditRowFunctionActive) {
      if (typeof blockConf.editRow === 'function') {
        console.log('HHHHHHHHHHHHHHHHHH');
        blockConf.editRow(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }
    if (blockConf.isAfterSaveFunctionActive) {
      if (typeof blockConf.afterSaveFunction === 'function') {
        blockConf.afterSaveFunction(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }
    //const data: any = {};
    //  data.operation = 'Save Without Validation';
    // this.refreshDataService.saveForm(data);
    if (this.originalValueForShowSaveNavBar) {
      this.refreshDataService.toggleShowSaveNavBar(true);
    }
    setTimeout(() => {
      const element = document.getElementById(this.selectedSectionId + '_' + this.confChange.name);
      element.scrollIntoView();
    }, 100);
    if (blockConf.isAfterSaveFunctionActive) {
      if (typeof blockConf.afterSaveFunction === 'function') {
        blockConf.afterSaveFunction(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }
    //this.beforeFilteringRows = cloneDeep(this.bphChange.rows);
    if (this.confChange.isTablePaginated) {
      this.bphChange.rows = this.bphChange.rows.map((row, index) => {
        row.isHidden = index > (this.pageSize  - 1);
        return row;
      });
      this.pageIndex = 0;
    }
  }

  getTotalDynamic(rows, column, j, dynaIndex, row, currency, totalText) {
    if (totalText) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        let tcolumn;
        for (let i = j - 1; i >= 0; i--) {
          if (rows[i].isTotal && rows[i].column === tcolumn) {
            break;
          }
          if (!rows[i].isTotal) {
            let newCellVal = 0;
            if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'][dynaIndex], 10))) {
              newCellVal = Math.round(parseFloat(rows[i][column]['value'][dynaIndex]) * 100) / 100;
            }
            r = r + newCellVal;
          }
          if (row) {
            if (!row[column]) {
              row[column] = {};
            }
            row[column]['value'] = r;
          }
        }
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '';
      }
      return currencySign;
    }
  }

  editRow(blockData, blockConf, i) {
    blockConf.isAddRowFunctionActive = false;
    blockConf.isEditRowFunctionActive = true;
    this.selectedTableRowIndex = i;
    this.copyBlockData = _.cloneDeep(this.bphChange);
    if (blockConf.type === 'SchedulerTable') {
      blockData.rowsForSchedulerTable[i].rowIndex = i;
      blockData.selectedRowIndex = i;
    }
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }
    /*this.addMode = false;
    this.isRemoveMode = false;
    this.isEditMode = true;
    this.editMode = true;
    this.isRowTeamEditToUpdate = true;
    this.selectedblockConf = blockConf; */


    blockData.selectedRow = blockData.rows[i];
    blockConf.showDetails = true;
    blockData.showDetails = true;
    this.globalConf.showDetails = true;
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    if (blockConf.name !== 'sub_table' || blockConf.isolateSectionOnEdit) {
      for (let k = 0; k < globalArray.length; k++) {
        try {
          ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
          ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).showDetails = false;
          ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
        } catch (e) {
        }
      }
      if (this.confChange.selectedContainerName && this.confChange.selectedContainerName.split('.')[0]) {
        this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
          if (row.sub_table) {
            row.sub_table.showDetails = false;
          }
        });
        this.bphChange.showDetails = true;
      }
    }

    blockData['selectedRowIndex'] = i;
    if (this.confChange.editRowFunction) {
      this.confChange.editRowFunction(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    // this.copyBlockData = _.cloneDeep(this.bphChange);
    window.scrollTo(0, 0);
  }

  orderTableBycolumn(blockData, blockConf, column) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      blockData.rows = _.orderBy(blockData.rows, column);
    }
  }

  orderTable(blockData, blockConf) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      const groupByColumns = blockConf.orderedBy.split(',');
      if (blockConf.orderedByTypes) {
        const groupByTypes = blockConf.orderedByTypes.split(',');
        blockData.rows = _.orderBy(blockData.rows, groupByColumns, groupByTypes);
      } else {
        blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      }
    }
  }

  createArrayOfLength(n): any[] {
    return Array.from(Array(n).keys());
  }

  filterHideCellSubBlocks(subBlocks: any[]): any[] {
    return subBlocks.filter(e => !e.hideCell);
  }

  reshuffleTable(blockData, blockConf) {
    if (blockConf && blockConf.showGroupBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      if (blockData.rows.length === 0) {
        return;
      }
      const groupByColumns = blockConf.groupedBy.split(',');
      blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      for (let i = groupByColumns.length - 1; i >= 0; i--) {
        if (i - 1 >= 0) {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, groupByColumns[i - 1]);
        } else {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, undefined);
        }
      }
    }
  }

  getValueForFbSelectSubBlock(currentValue, lang) {
    if (currentValue.value && currentValue.value.value >= ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
      return currentValue.otherValue;
    } else {
      if (currentValue.value && currentValue.value.label) {
        return currentValue.value.label[lang];
      } else {
        return '';
      }
    }
  }

  getOtherValueForFbSelectSubBlock(currentValue) {
    return currentValue.otherValue;
  }

  reshuffleColumn(blockData, blockConf, gbcolumn, k, gPreviouscolumn) {
    let oldCol = 0;
    const indexes = [];
    const groupColumn = gbcolumn.split('.')[0];
    if (gPreviouscolumn) {
      eval('oldCol = blockData.rows[0].' + gbcolumn + ' +\'_\' +blockData.rows[0].' + gPreviouscolumn);
    } else {
      eval('oldCol = blockData.rows[0].' + gbcolumn);
    }
    /* Ignore tslint error: "ESLint: 'rowSpanRow' is never reassigned, use 'const' instead."
    It is being reassigned on line #552. Changing to const will cause errors in the form */
    let rowSpanRow: any = {};
    eval('rowSpanRow = blockData.rows[0].' + groupColumn);
    rowSpanRow.rowspan = 0;
    let newCol;
    for (let i = 0; i < blockData.rows.length; i++) {
      if (!blockData.rows[i].isTotal) {
        if (gPreviouscolumn) {
          eval('newCol = blockData.rows[i].' + gbcolumn + ' +\'_\' +blockData.rows[i].' + gPreviouscolumn);
        } else {
          eval('newCol = blockData.rows[i].' + gbcolumn);
        }
        console.log('groupColumn');
        console.log(groupColumn);
        if (oldCol !== newCol) {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=false');
          indexes.push(i);
          oldCol = newCol;
          eval('rowSpanRow = blockData.rows[i].' + groupColumn);
          rowSpanRow.rowspan = 1;
        } else {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=true');
          rowSpanRow.rowspan++;
        }
      } else {
        rowSpanRow.rowspan++;
      }
    }
    eval('blockData.rows[0].' + groupColumn + '.hideCell=false');
    indexes.push(blockData.rows.length + 1);
    let moved = 0;
    for (let i = 0; i < blockConf.subBlocks.length; i++) {
      if (blockConf.subBlocks[i].name === groupColumn && !blockConf.subBlocks[i].noSubTotal) {
        for (let i = 0; i < indexes.length; i++) {
          blockData.rows.splice(indexes[i] + moved, 0, {'isTotal': true, 'position': k, 'column': groupColumn});
          moved++;
        }
      }
    }
  }
  handleEnterKey(event , confChange){
    if (!confChange.readOnlyTable) {
      event.preventDefault();
    }
  }

  deleteRow(blockData, blockConf, i) {
    console.log('iiiiiiiiiiiii');
    console.log(i);
    const index = i;
    if (blockConf.isConfirmDelete) {
      if (typeof blockConf.confirmDelete === 'function') {
        if (blockConf.subBlocks) {
          for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
            if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
              const subBlockName = blockConf.subBlocks[ii].name;
              if (blockData.rows[i][subBlockName].value) {
                for (let iiTobd = 0; iiTobd < blockData.rows[i][subBlockName].value.length; iiTobd++) {
                  if (blockData.rows[i][subBlockName].value[iiTobd] && blockData.rows[i][subBlockName].value[iiTobd].fileUploaded) {
                    if (!this.globalPrj.upload_to_be_deleted) {
                      this.globalPrj.upload_to_be_deleted = [];
                    }
                    this.globalPrj.upload_to_be_deleted.push(blockData.rows[i][subBlockName].value[iiTobd].id);
                  }
                }
              }
            }
          }
        }
        blockConf.confirmDelete(this.globalPrj, this.globalConf, this.matDialog, this.translate, i, this, this.dialogService);
      } else {
        if (this.confChange.isConfirmDeleteText) {
          const confirmDialogRef = this.matDialog.open(ECaseConfirmDialogComponent, {
            width: '700px',
          });
          const instance = confirmDialogRef.componentInstance;
          instance.confirmMessage = this.confChange.isConfirmDeleteText[this.selectedLanguage];
          confirmDialogRef.afterClosed().subscribe((decision) => {
            if(decision){
              const deletedRow = _.cloneDeep(blockData.rows[i]);
              this.deleteTableRow(blockData, blockConf, i);
              if (this.confChange.deleteRowFunction) {
                this.confChange.deleteRowFunction(this.globalPrj, this.globalConf, index, this, this.selectedLanguage, deletedRow);
              }
            }
          });
        }
      }
    } else {
      const deletedRow = _.cloneDeep(blockData.rows[i]);
      this.deleteTableRow(blockData, blockConf, i);
       if (this.confChange.deleteRowFunction) {
        this.confChange.deleteRowFunction(this.globalPrj, this.globalConf, index, this, this.selectedLanguage,deletedRow);
      }
    }
    this.beforeFilteringRows = cloneDeep(this.bphChange.rows);
    if (this.confChange.isTablePaginated) {
      this.bphChange.rows = this.bphChange.rows.map((row, _index) => {
        row.isHidden = _index > (this.pageSize  - 1);
        return row;
      });
      this.pageIndex = 0;
    }
  }

  // Do not delete, used in block js of some tables.
  deleteMultiYearRow(i, blockData, blockConf) {
    blockData.noOfYears = blockData.noOfYears ? blockData.noOfYears : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.noOfYears, 10)) ? 1 : parseInt(blockData.noOfYears, 10);
    const rowModulesIndex = (i % (noOfYears + 1)).toString();
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName]?.value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][rowModulesIndex + ii.toString()][subBlockName].value.length; iiTobd++) {
              if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd] && ((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }

    blockData.rows.splice(i, noOfYears + 1);
    // this.resuffleTable(blockData, blockConf);
  }

  deleteTableRow(blockData, blockConf, i) {
    this.pendingChangesGuard.isPristine = false;
    this.globalPrj.isConfirmationSaved = false;
    /*this.isRemoveMode = true;
    this.isRowTeamEditToUpdate = false;
    this.addMode = false;
    this.isEditMode = false;*/
    console.log('XXXXX');
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (blockData.rows[i][subBlockName].value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][subBlockName].value.length; iiTobd++) {
              if (blockData.rows[i][subBlockName].value[iiTobd] && blockData.rows[i][subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(blockData.rows[i][subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }
    blockData.rows.splice(i, 1);
    this.reshuffleTable(blockData, blockConf);
    this.bphChange = _.cloneDeep(blockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;

    if (blockConf.isDeleteRowFunctionActive) {
      if (typeof blockConf.afterDeleteRowFunction === 'function') {
        blockConf.afterDeleteRowFunction(this.globalPrj, this.globalConf, this, this.bphChange);
      };
    };
  }

  getGlobalTotal(rows, column, j, row, currency, totalText, confChange) {
    if (totalText && !confChange.isCountRows) {
      return totalText[this.selectedLanguage];
    } else {
      let r = 0;
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = 0; i < rows.length; i++) {
          if (!rows[i].isTotal && !rows[i].skipTotal) {
            let newCellVal = 0;
            if (rows[i][column] && confChange.type === 'Checkbox') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.filter(item => item.value === true).length;
            } else if (rows[i][column] && confChange.type === 'select') {
              const arr = rows[i][column]['value'];
              newCellVal = arr.value ? 1 : 0;
            } else if (rows[i][column] && confChange.type === 'text' && confChange.isCountRows) {
              const arr = rows[i][column]['value'];
              newCellVal = arr ? 1 : 0;
            } else if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
              newCellVal = parseFloat(rows[i][column]['value']);
            }
            r = r + newCellVal;
          }
        }
        // r = Math.round(r * 10) / 10;
        const newTotalRow = {};
        newTotalRow['isTotal'] = true;
        newTotalRow['value'] = Math.round(r * 100) / 100;
      } else {
        r = null;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '0,0';
      }
      let total = '';
      if (confChange.inputSuffix) {
        total = (confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed))
          : this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed)) + ' ' + confChange.inputSuffix;
      } else {
        total = confChange.isCountRows ? (totalText[this.selectedLanguage] + ' ' + this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed))
          : this.eCaseNumberFormatterPipe.transform(r, currency, null, this.selectedLanguage, null, confChange.decimalPlacesAllowed);
      }
      return total;
    }
  }

  calculateMultiplication(n1, n2) {
    let res = 0;
    try {
      if (n1 !== null && n2 !== null) {
        res = n1 * n2;
      }
    } catch (e) {
      return 0;
    }
    return res;
  }

  calculateDivision(n1, n2) {
    let res = 0;
    try {
      if (n1 !== null && n2 !== null && n2 !== 0) {
        res = n1 / n2;
      }
    } catch (e) {
      return 0;
    }
    return res;
  }

  getExpressionOutputNumber(row, label, lang, i) {
    let r;
    try {
      eval('r = ' + label);
    } catch (e) {
    }
    if (isNaN(r)) {
      r = '';
    }
    return r;
  }

  getExpressionOutput(row, label, lang, i) {
    const r = '';
    try {
      eval('r = ' + label);
    } catch (e) {
    }
    return r;
  };

  cancel(blockConf, blockData) {
    console.log('ccaaannnccceeelllle');
    console.log(blockConf);
    console.log(blockData);
    blockConf.showDetails = false;
    blockData.showDetails = false;
    this.globalConf.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).showDetails = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if (this.confChange.selectedContainerName) {
      console.log(this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows);
      this.globalPrj[this.selectedSectionId][this.confChange.selectedContainerName].rows.forEach((row) => {
        if (row.sub_table) {
          row.sub_table.showDetails = false;
        }
      });
    }
    if (this.originalValueForShowSaveNavBar) {
      this.refreshDataService.toggleShowSaveNavBar(true);
    }
    console.log(this.copyBlockData);
    this.bphChange = _.cloneDeep(this.copyBlockData);
    this.bphChange.showDetails = false;
    if (this.teamTableBlockName && this.teamTableNestedTableBlockName) {
      this.globalPrj[this.selectedSectionId][this.teamTableBlockName].rows[this.teamTableIndex][this.teamTableNestedTableBlockName] = this.bphChange;
    } else {
      (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
    }
    this.refreshDataService.tableCancelClicked(this.globalPrj);
    setTimeout(() => {
      const element = document.getElementById(this.selectedSectionId + '_' + this.confChange.name);
      element.scrollIntoView();
    }, 100);
  }

  isDialogOptionsEnabledForList(option): boolean {
    return this.confChange.showDialogForOptions && this.confChange.optionsDialogList.filter(item => item.id === option).length > 0;
  }

  openDialogForOptions(option): void {
    const dialogObj = this.confChange.optionsDialogList.filter(item => item.id = option)[0];
    this.openDialog(dialogObj);
  }

  getColumnAsRowSubBlocks() {
    return this.confChange.subBlocks.filter((item: { columnAsRow: boolean }) => item.columnAsRow);
  }

  getFilteredSubBlocks() {
    return this.confChange.subBlocks.filter((item: { columnAsRow: boolean }) => !item.columnAsRow);
  }

  getColumnAsRowColspan(){
    const showDeleteButtonCount = this.confChange.showDelete ? 1 : 0;
    const showEditButtonCount = !this.confChange.hideEditButton ? 1 : 0;
    const deleteEditRowCount = showDeleteButtonCount + showEditButtonCount;

    const totalSubBlocks = this.confChange.subBlocks.length;
    const columnAsRowCount = this.confChange.subBlocks.filter((item: { columnAsRow: boolean }) => item.columnAsRow).length;

    return totalSubBlocks - columnAsRowCount + deleteEditRowCount;
  }

  ngOnDestroy(): void {
    this.confChange.showDetails = false;
    this.bphChange.showDetails = false;
    this.showDetails = false;
    this.globalConf.showDetails = false;
    if (this.originalValueForShowSaveNavBar) {
      this.refreshDataService.toggleShowSaveNavBar(true);
    }
    this.subscription.unsubscribe();
    this.formValidationSubscription.unsubscribe();
  }
}
